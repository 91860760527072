@import "./../../main/style.scss";


.about-us__wrapper {
    position: relative;

    .about-us__lava-img {
        height: calc(100% + 300px);
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        filter: drop-shadow(-15px 4px 4px #F29C25);
        z-index: -1;
        user-select: none;
    }
}


.about-us {
    margin-bottom: 140px;
    padding: 140px 180px 0;

    &__row {
        @include display-center(space-between, center);
        column-gap: 30px;

        .about-us__content {
            opacity: 0;
			transform: translateX(-100px);

            .about-us__content-box {
                width: 100%;
          

                &--header {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                &--footer {
                    width: 100%;
                    display: flex;
                    justify-content: center;

                    img {
                        width: 400px;
                        opacity: 1;
                        transform: translateX(100px);
            
                        &.animate {
                            transition: .5s;
                            transform: translateX(0px);
                            opacity: .8;
                        }
                    }
                }
            }

			&.animate {
				transition: .5s;
				transform: translateX(0px);
				opacity: .8;
			}

            
            .about-us__title {
                margin-bottom: 50px;
                
            }

            .about-us__text {
                width: 100%;
                margin-bottom: 30px;
                @include font($dark, 24px, 400, normal, 0);

                &--second {
                    margin-top: 30px;
                }
            }



            .about-us__text_secondary {
                font-weight: 400;
            }

            .about-us__experience {
                display: flex;
                flex-direction: column;
                margin-top: 40px;
                column-gap: 40px;
                text-align: center;

                & p {
                    width: 100%;
                }

                &-text {
                    width: 100%;
                    @include font($dark, 24px, 400, normal, 0);
                    text-align: center;
                }
            }
        }

        
    }
}



@media screen and (max-width: 2000px) {
    .about-us {
        padding: 140px 160px 0;
    }
}


@media screen and (max-width: 1700px) {
    .about-us  {
        padding: 140px 120px 0;

       

        &__row {
            .about-us__content {
                .about-us__text {
                    font-size: 18px;

                }
    
                .about-us__experience {
                    &-text {
                        font-size: 18px;
                    }
                }
            }

            .about-us__row .about-us__content .about-us__content-box--footer img {
                width: 400px;
            }
        }
    }
}


@media screen and (max-width: 1200px) {
    .about-us__wrapper {
        .about-us__lava-img {
            right: -1%;
        }
    }

    
    

    .about-us {
        padding: 0 80px;
        padding-top: 140px;
        &__row {
            .about-us__content {
                .about-us__title {
                    margin-bottom: 30px;
                }

                .about-us__text {
                    font-size: 16px;
                }

                .about-us__experience {
                    flex-direction: column;

                    & p {
                        width: 100%;
                    }
                    &-text {
                        font-size: 16px;
                    }
                }
            }

           
        }
    }
}



@media screen and (max-width: 800px) {
    .about-us__wrapper {
        .about-us__lava-img {
            left: -15%;
        }

        .about-us__lava-img2 {
            height: calc(100% + 300px);
            right: -15%;
        }
    }

    .about-us__content {
        .about-us__content-box {
            flex-direction: column;
            align-items: center;

            & p {
                width: 100% !important;
                text-align: center;
            }
            
            &--header {
                width: 100% !important;
                margin-bottom: 50px;
            }

            &--footer {
                width: 100%;
                display: flex;
                justify-content: center;
                img {
                    width: 250px !important;
                    z-index: -1 !important;
                }
            }
        }
    }



    .about-us {
        padding: 0 30px;
        padding-top: 140px;

        &__row {
            flex-direction: column;
            column-gap: 0px;
            row-gap: 50px;

    
            .about-us__content {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                .about-us__title {
                    text-align: center;
                }
    
                .about-us__text {
                    max-width: 85%;
                    text-align: center;
                    font-size: 16px;
                }
    
                .about-us__experience {
                    margin: 0 auto;
                    align-items: center;
                    margin-bottom: 50px;

                    &-text {
                        & svg {
                            min-width: 30px;
                            min-height: 30px;
                        }

                        font-size: 16px;
                    }
                }
            }
    
        }
    }
}


@media screen and (max-width: 500px) {

    .about-us__content .about-us__content-box--header {
        margin-bottom: 20px !important;
    }

    .about-us__wrapper {
        .about-us__lava-img {
            display: none;
        }
        .about-us__lava-img2 {
            display: none;
        }
    }

   

    .about-us__content {
        .about-us__content-box {
            flex-direction: column;
            align-items: center;

            & p {
                width: 100% !important;
                text-align: center;
            }
            
            &--header {
                width: 100% !important;
            }

            &--footer {
                width: 100%;
                position: absolute;
                bottom: 5%;
                img {
                    min-width: 200px !important;
                }
            }
        }
    }


    .about-us {
        &__row {
            .about-us__content {
                max-width: 100%;

                .about-us__text {
                    font-size: 16px;
                }

                .about-us__experience {
                    margin-bottom: 250px;
                    column-gap: 20px !important;
                }
            }

            .about-us__image {
                max-width: 100%;
            }
        }
    }
}