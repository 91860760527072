@import "../../main/style.scss";


.contact__message {
	position: fixed;
	left: 50%;
	top: -100px;
	transform: translateX(-50%);

	width: 500px;
	height: 80px;

	background-color: $main;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);

	border-radius: 5px;
	z-index: 5;
	@include display-center(center, center);
	transition: .5s;

	&._active {
		transition: .5s;
		top: 40px;
	}


	& p {
		@include font($white, 24px, 600, normal, 0);
	}
}

.error-message {
	width: 100%;

	@include font($main, 20px, 500, normal, 0);
	margin-top: 5px;
}

.contact {
	position: relative;
	width: 100%;
	background-image: url(../img/contact__bg.jpg);
	background-size: cover;
	@include display-center(flex-start, center);
	flex-direction: column;
	padding: 0 180px 380px;

	overflow-x: hidden;


	&__title {
		text-align: center;
		margin: 80px 0 40px;
		color: $white !important;
		transform: translateY(100px);
		opacity: 0;

		&.animate {
			transition: .3s;
			opacity: 1;
			transform: translateY(0px);
		}
	}

	&__box {
		width: 100%;
		@include display-center(center, center);

		&-left {
			width: 50%;
			height: auto;
			opacity: 0;
			transform: translateX(-100px);

			&.animate {
				transition: .5s;
				transform: translateX(0px);
				opacity: 1;
			}

			.contact__secondary {
				margin: 30px 0;
				&-title {
					@include font($white, 28px, 400, normal, 0);
					margin-bottom: 10px;
				}

				&-input {
					width: 80%;
					height: 90px;
					@include font($input, 24px, 500, normal, 0);
					padding: 0 15px;
					border-radius: 3px;
					outline: none;
				}

				&-input2 {
					width: 80%;
					height: 180px;
					@include font($input, 24px, 500, normal, 0);
					padding:15px;
					resize: none;
					outline: none;
					border-radius: 3px;
					&::-webkit-scrollbar {
						width: 6px;
					  }
					  
					&::-webkit-scrollbar-track {
						background: #f1f1f1;
					}
					  
					&::-webkit-scrollbar-thumb {
						background: $main;
						border-radius: 6px;
					}
				}
			}

			.contact__button {
				width: 80%;
				height: 90px;
				@include display-center(center, center);
				@include font($dark, 24px, 700, normal, 0);
				border: none;
				border-radius: 3px;
				margin-top: 35px;
				transition: 0.5s;

				&:hover {
					background-color: $main;
					color: $white;
					transition: 0.5s;
				}
			}
		}

		&-rigth {
			width: 60%;
			height: auto;
			@include display-center(flex-end, center);
			opacity: 0;
			transform: translateX(100px);

			&.animate {
				transition: .5s;
				transform: translateX(0px);
				opacity: 1;
			}

			.main {
				&__element {
					width: 450px;
					height: 840px;
					border-right: 12px solid $main;
					border-top: 12px solid $main;
					border-bottom: 12px solid $main;
					
					border-top-left-radius: 5px;
					border-bottom-left-radius: 5px;
					border-top-right-radius: 5px;
					border-bottom-right-radius: 5px;
					position: absolute;
					left: 50%;

					
					@include display-center(flex-end, center);
					position: relative;
		
					&-title {						
						iframe {
							width: 850px;
							height: 550px;
							margin-right: 220px;
						}
					}
		
					&-color {
						color: $main;
					}
		
					&::after {
						content: "";
						width: 12px;
						height: 100px;
						position: absolute;
						right: 100%;
						top: 0px;
						margin-right: -12px; 
						background-color: $main;
		
					}
		
					&::before {
						content: "";
						width: 12px;
						height: 100px;
						position: absolute;
						right: 100%;
						bottom: 0px;
						margin-right: -12px;
						background-color: $main;
					}
				}
		
			}
		}
	}
}


@media screen and (max-width: 2000px) {
	.contact {
		padding: 0 160px 380px;


		&__box {
			&-left {
				.contact__secondary {
					margin: 20px 0;	
					&-title {
						font-size: 24px;
					}
	
					&-input {
						height: 80px;
					}
	
					&-input2 {
						height: 160px;
					}
				}
	
				.contact__button {
					height: 80px;
				}
			}


			&-rigth {
				.main {
					&__element {
						width: 400px;
						height: 760px;
						border-right: 7px solid $main;
						border-top: 7px solid $main;
						border-bottom: 7px solid $main;


						&-title {						
							iframe {
								width: 700px;
								height: 500px;
								margin-right: 220px;
							}
						}

						&::after {
							width: 10px;
							height: 80px;
							margin-right: -7px; 
			
						}
			
						&::before {
							width: 10px;
							height: 80px;
							margin-right: -7px;
						}
					}
				}
			}
		}	
	}
}


@media screen and (max-width: 1700px) {
	.contact {
		padding: 0 120px 550px;
	
		&__title {
			margin-top: 80px;
		}
	
		&__box {
			&-left {
	
				.contact__secondary {
					margin: 15px 0;
					&-title {
						font-size: 20px;
						margin-bottom: 5px;
					}
	
					&-input {
						height: 70px;
					}

					&-input2 {
						height: 170px;
					}
				}
	
				.contact__button {
					height: 70px;
				}
			}
	
			&-rigth {
				.main {
					&__element {
						width: 450px;
						height: 700px;
						border-right: 7px solid $main;
						border-top: 7px solid $main;
						border-bottom: 7px solid $main;
						

						&-title {						
							iframe {
								width: 600px;
								height: 400px;
								margin-right: 220px;
							}
						}
			
						&-color {
							color: $main;
						}
			
						&::after {
							width: 7px;
							height: 100px;
							margin-right: -7px; 
						}
			
						&::before {
							width: 7px;
							height: 100px;
							margin-right: -7px;
						}
					}
			
				}
			}
		}
	}

}

@media screen and (max-width: 1550px) {
	.contact {
		padding-bottom: 450px;
	}
}

@media screen and (max-width: 1370px) {

	.error-message {
		width: 60%;
	
	}

	.contact {
		padding: 0 60px 290px;

		width: 100%;
		@include display-center(center, center);

		
		&__box {
			flex-direction: column;
			&-left {
				width: 100%;
				flex-direction: column;
				@include display-center(center, center);
				.contact__secondary {
					width: 100%;
					@include display-center(flex-start, center);
					flex-direction: column;
					&-title {
						width: 60%;
					}

					&-input {
						width: 60%;
	
					}
	
					&-input2 {
						width: 60%;
					}
				}

				.contact__button {
					width: 60%;
				}


			}

			&-rigth {
				width: 100%;
				height: auto;
				@include display-center(center, center);
	
				.main {
					width: 100%;

					&__element {
						width: 100%;
						height: 700px;
						border: none;
						@include display-center(center, center);
						position: static !important;
			
						&-title {						
							iframe {
								width: 600px;
								height: 400px;
								margin-right: 0px;
								border-radius: 5px;

							}
						}
			
						&::after {
							display: none;
						}
			
						&::before {
							display: none;
						}
					}
			
				}
			}
		}
	}
}


@media screen and (max-width: 1200px) {
	.contact {
		padding: 0 60px 290px;
	}
}


@media screen and (max-width: 1050px)  {
	.contact {
		padding: 0 60px 290px;
	}
}



@media screen and (max-width: 820px) {
	.contact {
		padding: 0 60px 470px;
	}
}


@media screen and (max-width: 800px) {

	.error-message {
		width: 100%;
	
		@include font($main, 20px, 500, normal, 0);
		margin-top: 5px;
	}
	
	.contact__message {
		width: 400px;
		height: 60px;
	
		&._active {
			top: 30px;
		}
	
		& p {
			font-size: 22px;
		}
	}
	

	.contact {
		padding: 0 30px 970px;

		&__box {
			&-left {
				width: 100%;
				flex-direction: column;
				@include display-center(center, center);
				.contact__secondary {
					width: 100%;
					@include display-center(flex-start, center);
					flex-direction: column;
					&-title {
						width: 100%;
					}

					&-input {
						width: 100%;
						font-size: 20px;
					}
	
					&-input2 {
						width: 100%;
						font-size: 20px;
					}
				}

				.contact__button {
					width: 100%;
					font-size: 20px;
				}


			}

			&-rigth {
				width: 100%;
				height: auto;
				@include display-center(center, center);
	
				.main {
					width: 100%;

					&__element {
						width: 100%;
						height: 700px;
						border: none;
						@include display-center(center, center);
						position: static !important;
			
						&-title {		
							width: 100%;				
							iframe {
								width: 100%;
								height: 400px;
							}
						}
					}
			
				}
			}
		}
	}
}


@media screen and (max-width: 500px) {
	.contact__message {
		width: 75%;
	
		&._active {
			top: 30px;
		}
	
		& p {
			font-size: 20px;
		}
	}

	.contact {
		padding: 0 30px 800px;

		&__box {
			&-left {
				.contact__secondary {
					&-title {
						@include font($white, 18px, 400, normal, 0);
					}
				}

				.contact__button {
					z-index: 2;
				}
			}
			&-rigth {
				position: relative;
				bottom: 100px;

				.main {
					&__element {
						width: 100%;
						height: 700px;
			
						&-title {		
							width: 100%;				
							iframe {
								width: 100%;
								height: 300px;
							}
						}
					}
			
				}
			}
		}
	}
}
