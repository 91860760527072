@import "./../../main/style.scss";



.products__wrapper {
    background-image: url(../img/products-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;

    .products {
        position: relative;
        padding: 0 180px;


        &__title {
            padding-top: 140px;
            margin-bottom: 50px;
            text-align: center;
            color: $white;
            transform: translateY(100px);
            opacity: 0;

            &.animate {
                transition: .3s;
                opacity: 1;
                transform: translateY(0px);
            }
        }


        &__container {
            padding-bottom: 420px;
            width: 1400px;
            margin: 0 auto;

            @include display-center(space-between, center);
            flex-wrap: wrap;
            gap: 40px;

            .product-card {
                transform: translateY(100px);
                opacity: 0;
    
                &.animate {
                    transition: .3s;
                    opacity: 1;
                    transform: translateY(0px);
                }
            }
        }
        

        &__reference {
            position: absolute;
            left: 50%;  
            transform: translate(-50%, -50%) translateY(100px);
            height: auto;
            border-radius: 5px;
            background: $white;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            opacity: 0;

            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 5;


            &.animate {
                transition: .3s;
                opacity: 1;
                transform: translate(-50%, -50%);
            }


            .products__reference__certificate__box {
                cursor: pointer;
                .products__reference__img {
                    width: 495px;
                    height: 640px;
                    cursor: pointer;
                }
            }



            // &-header {
            //     margin-bottom: 40px;
            //     @include display-center(space-between, center);

            //     &__logo {
            //         width: 200px;
            //         height: 65px;
            //         background-color: $dark;
            //     }

            //     &__text {
            //         display: flex;
            //         flex-direction: column;
            //         align-items: flex-end;

            //         &-name {
            //             display: flex;
            //             align-items: center;
            //             column-gap: 10px;
            //             @include font($dark, 24px, 500, normal, 0);
            //             text-align: right;
            //         }

            //         &-company-name {
            //             @include font($dark, 24px, 600, normal, 0);
            //         }
            //     }
            // }


            // &-text {
            //     @include font($dark, 20px, 400, normal, 0);
            // }

            // &-btn {
            //     margin-top: 25px;
            //     width: 200px;
            //     height: 44px;
            //     background-color: $main;
            //     border-radius: 5px;
            //     border: none;
            //     @include font($white, 16px, 400, normal, 0);
            // }
        }

        
        .products__reference__popup-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            background-color: rgba(0, 0, 0, 0.2);
            z-index: 15;
            overflow-x: hidden;
            overflow-y: auto;
            display: flex;
            @include display-center(center, center);

            .products__reference__popup-wrapper {
                // min-height: 800px;
                overflow-y: auto;  
                overflow-x: hidden;
            }

            &._active {
                display: flex;
            }
    
            &._hide {
                display: none;
            }
    
            .products__reference__img {
                width: 570px;
                height: 800px;
            }
        }
    }
}


@media screen and (max-height: 800px) {
    .products__wrapper {
        .products {
            .products__reference__popup-overlay {
                justify-content: center;
                align-items: start;
            }
        }
    }
}


@media screen and (max-width: 2000px) {
    .products__wrapper {
        .products {
            padding: 0 160px;
    
    
            &__title {
                padding-top: 140px;
                margin-bottom: 50px;
                text-align: center;
                color: $white;
            }
    
    
            &__container {
                // padding-bottom: 270px;
                width: 1300px;
                margin: 0 auto;
    
                @include display-center(space-between, center);
                flex-wrap: wrap;
                gap: 40px;
            }
            
    
            &__reference {
                // width: 1300px;
                // height: auto;
    
    
                // &-header {
                //     &__logo {
                //         width: 156px;
                //         height: 50px;
                //     }
    
                //     &__text {
                //         &-name {
                //             font-size: 20px;
                //         }
                //         &-company-name {
                //             font-size: 20px;
                //         }
                //     }
                // }
    
    
                // &-text {
                //    font-size: 16px;
                // }
            }
        }
    }
}



@media screen and (max-width: 1700px) {
    .products__wrapper {
        .products {
            padding: 0 120px;
            
    
            &__title {
                padding-top: 140px;
                margin-bottom: 50px;
            }
    
            &__container {
                width: 100%;
                // padding-bottom: 320px;
                @include display-center(space-around, center);
            }
            
            &__reference {
                // width: calc(100% - 240px);
                // height: auto;
                // padding: 30px 40px 30px 30px;
            }
        }
    }
}



@media screen and (max-width: 1200px) {
    .products__wrapper {
        .products {
            padding: 0 60px;
    
            &__title {
                padding-top: 140px;
                margin-bottom: 50px;
            }
    
    
            &__container {
                width: 100%;
                // padding-bottom: 300px;
            }
        }
    }
}


@media screen and (max-width: 1000px) {
    .products__wrapper {
        .products {
            &__container {
                width: 100%;
                // padding-bottom: 380px;
                @include display-center(space-around, center);
            }
        }
    }
}




@media screen and (max-width: 800px) {
    .products__wrapper {
        .products {
            padding: 0 30px;
        }
    }
}

@media screen and (max-width: 630px) {
    .products__wrapper {
        .products {
            .products__reference__popup-overlay {
                .products__reference__popup-wrapper {
                    .products__reference__img {
                        width: calc(100vw - 60px);
                        height: auto;
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 580px) {
    .products__wrapper {
        .products {
            padding: 0 30px;

            .products__reference__certificate__box {
                .products__reference__img {
                    width: calc(100vw - 60px);
                    height: auto;
                }
            }
        }
    }
}


@media screen and (max-width: 500px) {
    .products__wrapper {
        .products { 
            .products__reference__popup-overlay {
                display: none;
                &._active { display: none; }
                .products__reference__popup-wrapper {
                    display: none;
                    .products__reference__img {
                        display: none;
                    }
                }
            }
            
            &__container {
                padding-bottom: calc(230px + 35%);
            }

            &__reference {
                z-index: 2;

                &-header {
                    margin-bottom: 20px;
                    flex-direction: column;
                    row-gap: 10px;

                    &__text {
                        align-items: center;
    
                        &-name {
                            text-align: center;
                        }
                    }
                }


                &-text {
                    text-align: center;
                    max-height: 220px;
                    overflow-y: auto;

                    &::-webkit-scrollbar {
						width: 6px;
					  }
					  
					&::-webkit-scrollbar-track {
						background: #f1f1f1;
					}
					  
					&::-webkit-scrollbar-thumb {
						background: $main;
						border-radius: 6px;
					}
                }

                &-btn {
                    margin: 0 auto;
                    margin-top: 25px;
                    width: 100%;
                }
            }
        }
    }
}