@import "../../main/style.scss";

.faq {
    padding: 140px 120px;
    padding-top: 140px;
    margin: 140px 0;


    background-image: url(../img/faq-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    &__title {
        text-align: center;
        margin-bottom: 50px;
        transform: translateY(100px);
        color: $white !important;
		opacity: 0;

		&.animate {
			transition: .3s;
			opacity: 1;
			transform: translateY(0px);
		}
    }

    &__content {
        display: flex;
        align-items: center;
        flex-direction: column;
        row-gap: 50px; // 35


        .animation__left {
            transform: translateX(100px);
            opacity: 0;
        }

        .animation__right {
            opacity: 0;
            transform: translateX(-100px);
        }

        .animation__left.animate, .animation__right.animate {
            transition: 0.5s;
            opacity: 1;
            transform: translateX(0px);
        }
    }
}


@media screen and (max-width: 1200px) {
    .faq {
        padding: 140px 60px;
    }
}

@media screen and (max-width: 800px) {
    .faq {
        padding: 140px 30px;
    }
}