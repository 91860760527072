@import "./../../main/style.scss";

.certificate__container {
    padding: 0 180px;
    margin-top: 140px;
    @include display-center(space-around, center);
    flex-wrap: wrap;
    gap: 50px;

    .certificate__popup-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 15;
        overflow-x: hidden;
        overflow-y: auto;
        display: none;
        @include display-center(center, center);

        .certificate__popup-wrapper {
            // min-height: 700px;
            overflow-y: auto;  
            overflow-x: hidden;
        }

        &._active {
            display: flex;
        }

        &._hide {
            display: none;
        }

        .certificate__img {
            width: 570px;
            height: 800px;
        }
    }


    .certificate__box {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            width: 380px;
            height: 380px;
            background: $main;
            border-radius: 50%;
            opacity: 0.3;
        }

        .certificate__img {
            position: relative;
            width: 340px;
            height: 476px;
            cursor: pointer;
        }
    }
}

@media screen and (max-height: 800px) {
    .certificate__container {
        .certificate__popup-overlay {
            justify-content: center;
            align-items: start;
        }
    }
}


@media screen and (max-width: 2000px) {
    .certificate__container {
        padding: 0 160px;
    }
}


@media screen and (max-width: 1700px) {
    .certificate__container {
        padding: 0 120px;
    }
}

@media screen and (max-width: 1200px){
    .certificate__container {
        padding: 0 80px;
    }
}


@media screen and (max-width: 1200px) and (max-height: 500px) {
    .certificate__container {
        .certificate__popup-overlay {
            justify-content: center;
            align-items: start;
        }
    }
}


@media screen and (max-width: 800px) {
    .certificate__container {
        padding: 0 30px;
    }
}

@media screen and (max-width: 700px) {
    .certificate__container {
        .certificate__popup-overlay {
            .certificate__img {
                width: calc(100vw - 60px);
                height: auto;
            }
        }
    }
}



@media screen and (max-width: 500px) {
    .certificate__container {
        .certificate__popup-overlay {
            display: none;

            &._active {
                display: none;
            }
        }

        .certificate__box {
            position: relative;

            &::before {
                width: 90vw;
                height: 90vw;
            }
    
            .certificate__img {
                position: relative;
                width: 100%;
                height: calc(100% * 0.7);
            }
        }
    }
}


@media screen and (max-width: 410px) {
    .certificate__container {
        .certificate__popup-overlay {
            .certificate__popup-wrapper {
                min-height: auto;
                width: 100%;
                padding: 0 30px;
            }
            .certificate__img {
                width: 100%;
                height: auto;
            }
        }

        .certificate__box {
            position: relative;
            &::before {
                width: 90vw;
                height: 90vw;
            }
    
            .certificate__img {
                position: relative;
                width: 100%;
                height: calc(100% * 0.7);
            }
        }
    }
}

@media screen and (max-width: 410px) and (max-height: 500px) {
    .certificate__container {
        .certificate__popup-overlay {
            justify-content: center;
            align-items: center;
        }
    }
}