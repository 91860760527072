@import './../../main/base/variables';
@import './../../main/base/mixins';



.gallery {
    margin-bottom: 140px;


    &__title {
        margin-bottom: 50px;
        text-align: center;
    }

    &__container {
        margin-bottom: 50px;

        .gallery-img {
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            // width: 400px;
            // height: 300px;

            width: 500px;
            height: 375px;
            border-radius: 5px;
            margin: 0 auto;


            &--1 { background-image: url(../img/gallery-img-1.jpg); } 
            &--2 { background-image: url(../img/gallery-img-2.jpg); }
            &--3 { background-image: url(../img/gallery-img-3.jpg); }
            &--4 { background-image: url(../img/gallery-img-4.jpg); }
            &--5 { background-image: url(../img/gallery-img-5.jpg); }
            &--6 { background-image: url(../img/gallery-img-6.jpg); }
            &--7 { background-image: url(../img/gallery-img-7.jpg); }
            &--8 { background-image: url(../img/gallery-img-8.jpg); }
            &--9 { background-image: url(../img/gallery-img-9.jpg); }
            &--10 { background-image: url(../img/gallery-img-10.jpg); }
            &--11 { background-image: url(../img/gallery-img-11.jpg); }
            &--12 { background-image: url(../img/gallery-img-12.jpg); }
            &--13 { background-image: url(../img/gallery-img-13.jpg); }
            &--14 { background-image: url(../img/gallery-img-14.jpg); }
            &--15 { background-image: url(../img/gallery-img-15.jpg); }
            &--16 { background-image: url(../img/gallery-img-16.jpg); }
            &--17 { background-image: url(../img/gallery-img-17.jpg); }
            &--18 { background-image: url(../img/gallery-img-18.jpg); }
            &--19 { background-image: url(../img/gallery-img-19.jpg); }
            &--20 { background-image: url(../img/gallery-img-20.jpg); }
            &--21 { background-image: url(../img/gallery-img-21.jpg); }
            &--22 { background-image: url(../img/gallery-img-22.jpg); }
            &--23 { background-image: url(../img/gallery-img-23.jpg); }
            &--24 { background-image: url(../img/gallery-img-24.jpg); }
            &--25 { background-image: url(../img/gallery-img-25.jpg); }
            &--26 { background-image: url(../img/gallery-img-26.jpg); }
        }
    }
}



@media screen and (max-width: 2200px) {
    .gallery {
        &__container {
            .gallery-img {
                width: 400px;
                height: 300px;
            }
        }
    }
}


@media screen and (max-width: 1700px) {
    .gallery {
        &__container {
            .gallery-img {
                width: 400px;
                height: 300px;
            }
        }
    }
}



@media screen and (max-width: 1400px) {
    .gallery {
        &__container {
            .gallery-img {
                width: 350px;
                height: 262px;
            }
        }
    }
}


@media screen and (max-width: 1100px) {
    .gallery {
        &__container {
            .gallery-img {
                width: 300px;
                height: 224px;
            }
        }
    }
}


@media screen and (max-width: 650px) {
    .gallery {
        &__container {
            .gallery-img {
                width: 250px;
                height: 187px;
            }
        }
    }
}

@media screen and (max-width: 570px) {
    .gallery {
        &__container {
            .gallery-img {
                width: 350px;
                height: 262px;
            }
        }
    }
}

@media screen and (max-width: 450px) {
    .gallery {
        &__container {
            .gallery-img {
                width: 260px;
                height: 195px;
            }
        }
    }
}