@import "./mixins";
@import "./variables";


body {
    overflow-x: hidden;
}

::-webkit-scrollbar {
    width: 20px;
}

.noselect {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none; 
    user-select: none;
}


.body-hidden {
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: $main;
}


::selection {
    background: rgba(242, 157, 37, 0.4);
}


.secondary-title {
    @include font($dark, 64px, 700, normal, 0);
}

  
@media screen and (max-width: 2000px) {
    ::-webkit-scrollbar {
        width: 20px;
    }
    .secondary-title {
        font-size: 50px;
    } 
}

@media screen and (max-width: 2000px) and (max-height: 850px) {
    ::-webkit-scrollbar {
        width: 12px;
      }

    .secondary-title {
        font-size: 50px;
    } 
}

@media screen and (max-width: 1700px) {
    .secondary-title {
        font-size: 48px;
    } 
}

@media screen and (max-width: 1700px) and (max-height: 750px) {
    .secondary-title {
        font-size: 44px;
    } 
}

@media screen and (max-width: 1200px) {
    .secondary-title {
        font-size: 32px;
    }
}
  