@import "./../../../main/style.scss";


.product-card {
    position: relative;
    padding: 15px 30px;

    width: 310px;
    height: 370px;

    background-color: $white;
    border-radius: 5px;
    box-shadow: 0px 0px 4.5px 0px rgba(242, 156, 37, 0.65);
    overflow: hidden;

    display: flex;
    flex-direction: column;
    align-items: center;


    &:hover {
        .product-card__hover {
            transform: translate(-50%, -50%) scale(1);
            transition: .5s;
        }
    }


    &__img {
        border-radius: 5px;
        width: 210px;
        height: 210px;
    }

    &__title {
        text-align: center;
        margin-top: 20px;
        @include font($dark, 28px, 600, normal, 0);

        &--gradient {
            background-image: linear-gradient(90deg, rgb(255, 132, 0) 0%, rgb(241, 165, 24) 93%);
            color: transparent; /* Делаем текст прозрачным */
            display: inline-block;
            -webkit-background-clip: text; /* Применяем градиентный цвет к тексту */
            background-clip: text;
        }
    }


    // &__hover {
    //     position: absolute;
    //     top: 50%;
    //     left: 50%;
    //     transform: translate(-50%, -50%)  scale(0);

    //     display: flex;
    //     align-items: center;
    //     justify-content: center;

    //     width: 500px;
    //     height: 500px;

    //     background: rgba(242, 156, 37, 0.90);
    //     border-radius: 50%;
    //     transition: .5s;

    //     &-text {
    //         text-align: center;
    //         @include font($white, 24px, 500, normal, 0);
    //         max-width: 230px;
    //     }
    // }
}


@media screen and (max-width: 2000px) {
    .product-card {
        width: 260px;
        height: 310px;


        &__img {
            width: 180px;
            height: 180px;
        }

        &__title {
            text-align: center;
            margin-top: 20px;
           font-size: 20px;
        } 


        &__hover {
            width: 400px;
            height: 400px;

            &-text {
                font-size: 20px;
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .product-card {
        width: 220px;
        height: 250px;


        &__img {
            width: 140px;
            height: 140px;
        }

        &__title {
            margin-top: 15px;
            font-size: 18px;
        } 


        &__hover {
            width: 350px;
            height: 350px;

            &-text {
                font-size: 18px;
                max-width: 160px;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .product-card {
        width: 260px;
        height: 310px;


        &__img {
            width: 180px;
            height: 180px;
        }

        &__title {
            text-align: center;
            margin-top: 20px;
           font-size: 20px;
        } 


        &__hover {
            width: 400px;
            height: 400px;

            &-text {
                font-size: 20px;
            }
        }
    }
}