@import "./../../main/style.scss";

.work-process__wrapper {
    position: relative;
    // overflow-x: hidden;

    .work-process__lava-img {
        position: absolute;
        right: 0%;
        top: 50%;
        transform: translateY(-35%);
        height: calc(300% + 2500px);
        z-index: -1;
        user-select: none;
    }


    .work-process {
        padding-top: 420px;
        display: flex;
        flex-direction: column;
        align-items: center;

        &__title {
            text-align: center;
            margin-bottom: 80px;
            max-width: 60%;
            transform: translateY(100px);
            opacity: 0;

            &.animate {
                transition: .3s;
                opacity: 1;
                transform: translateY(0px);
            }
        }

        &__container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            row-gap: 25px;

            .work-process__box {
                display: flex;
                align-items: center;
                column-gap: 50px;
                opacity: 0;

                &:nth-child(1), &:nth-child(1) {
                    transform: translateX(-100px);
                }

                &:nth-child(3) {
                    transform: translateX(100px);
                }

                &.animate {
                    transition: .3s;
                    opacity: 1;
                    transform: translateY(0px);
                }



                &-circle {
                    min-width: 100px;
                    min-height: 100px;
                    background-color: $main;
                    border-radius: 50%;
                    @include display-center(center, center);
                    svg {
                        width: 50px;
                        height: 50px;
                    }
                }

                &-content {
                    &__title {
                        max-width: 700px;
                        margin-bottom: 10px;
                        @include font($dark, 36px, 700,normal, 0);
                    }

                    &__text {
                        max-width: 800px;
                        @include font(#929292, 24px, 500, normal, 0);

                    }
                }
            }

            .work-process__arrow {
                width: 100%;
                transform: translateY(100px);
                opacity: 0;
    
                &.animate {
                    transition: .3s;
                    opacity: 1;
                    transform: translateY(0px);
                }
            }
        }

    }
}



@media screen and (max-width: 2000px) {
    .work-process__wrapper {
        .work-process__lava-img {
            transform: translateY(-40%) translateX(2%);
            height: calc(360% + 2200px);
        }

        .work-process {
            &__title  {
                max-width: 1000px;
            }
            
            // padding-top: 270px;
            &__container {
                .work-process__box {
                    &-content {
                        &__title {
                            max-width: 600px;
                            margin-bottom: 10px;
                            font-size: 32px;
                        }
    
                        &__text {
                            max-width: 630px;
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 1700px) {
    .work-process__wrapper {
        .work-process__lava-img {
            transform: translateY(-40%) translateX(2%);
            height: calc(400% + 2000px);
        }
        .work-process {
            // padding-top: 290px;
        }
    }
}

@media screen and (max-width: 1360px) {
    .work-process__wrapper {
        .work-process__lava-img {
            transform: translateY(-40%) translateX(5%);
            height: calc(450% + 2000px);
        }
    }
}



@media screen and (max-width: 1200px) {
    .work-process__wrapper {
        .work-process__lava-img {
            height: calc(300% + 200px);
        }

        .work-process {
            padding: 420px 60px 0;
            &__container {
                .work-process__box {
                    column-gap: 30px;

                    &-circle {
                        min-width: 100px;
                        min-height: 100px;
                    }

                    &-content {
                        &__title {
                            max-width: 500px;
                            margin-bottom: 10px;
                            font-size: 28px;
                        }
    
                        &__text {
                            max-width: 600px;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}



@media screen and (max-width: 1000px) {
    .work-process__wrapper {
        .work-process__lava-img {
            right: -18%;
            height: calc(300% + 1300px);
        }

        .work-process {
            &__container {
                .work-process__box {
                    &-circle {
                        & svg {
                            width: 60px;
                            height: 60px;
                        }
                    }

                    &-content {
                        &__title {
                            font-size: 24px;
                        }

                        &__text {
                            max-width: 500px;
                        }
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 800px) {
    .work-process__wrapper {
        .work-process__lava-img {
            right: -25%;
            height: calc(400% + 200px);

        }


        .work-process {
            padding: 420px 30px 0 30px;

            &__container {
                .work-process__box {
                    column-gap: 20px;
                    &-circle {
                        min-width: 80px;
                        min-height: 80px;

                        & svg {
                            width: 40px;
                            height: 40px;
                        }
                    }

                    &-content {
                        &__title {
                            max-width: 400px;
                            font-size: 24px;
                        }

                        &__text {
                            font-size: 18px;
                            max-width: 400px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .work-process__wrapper {
        .work-process__lava-img {
            display: none;
        }

        .work-process {
            padding: 420px 30px 0 30px;


            &__title {
                max-width: 100%;
            }

            &__container {
                .work-process__box {
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    
                    gap: 20px 0;

                    &-circle {
                        min-width: 80px;
                        min-height: 80px;

                        & svg {
                            width: 40px;
                            height: 40px;
                        }
                    }

                    &-content {
                        &__title {
                            font-size: 24px;
                        }

                        &__text {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 500px) {
    .work-process__wrapper {
        .work-process__lava-img {
            right: -62%;
        }

        .work-process {
            padding-top: calc(200px + 32%);

            &__container {
                .work-process__box {
                    &-content {
                        &__text {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 400px) {
    .work-process__wrapper {
        .work-process__lava-img {
            right: -80%;
        }

        .work-process {
            // padding-top: calc(310px + 30%);  
        }
    }
}

@media screen and (max-width: 360px) {
    .work-process__wrapper {
        .work-process__lava-img {
            right: -100%;
        }
    }
}