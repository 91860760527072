@import "../../main/style.scss";

.home {
	width: 100%;
	height: 100vh;
	background-image: url(../img/home__background-min.jpg);
	background-size: cover;
	padding: 0 180px;

	&__scroll-top {
		position: fixed;
		right: 30px;
		bottom: 20px;
		width: 50px;
		height: 50px;
		border-radius: 5px;
		background-color: $main;
		z-index: 9;
		cursor: pointer;
		@include display-center(center, center);
		box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.5);
		transform: scale(0);
		transition: .2s;


		&._open {
			transform: scale(1);
			transition: .2s;
		}
	}

	&__language {
		width: 0%;
		height: 100vh;
		position: fixed;
		left: 0%;
		top: 0%;
		z-index: 10;
		background-color: $blue;
		transition: 0.5s;
		display: none;
	}

	&__language-open {
		width: 100%;
		display: block;
		@include display-center(flex-end, center);
		animation-name: language__seconder;
		animation-duration: 2s;
		animation-iteration-count: 1;

			@keyframes language__seconder {
				0% {
					width: 100%;
				}
				50% {
					width: 100%;
				}
				100% {
					width: 0%;
				}
			}

	}

	.page__popup {
		width: 100%;
		height: 0vh;
		position: fixed;
		left: 0%;
		top: 0%;
		z-index: 10;
		background-color: $blue;
		@include display-center(center, center);
		animation-name: page__popup;
		animation-duration: 5s;
		animation-iteration-count: 1;

		@keyframes page__popup {
			0% {
				height: 100vh;
			}
			70% {
				height: 100vh;
			}
			100% {
				height: 0vh;
			}
		}

		.logo {
			min-width: 440px;
			min-height: 140px;
			background-image: url(../../main/img/logo.svg);
			background-size: cover;
			animation-name: logo;
            animation-duration: 3.5s;
            animation-iteration-count: 1;
			opacity: 0;
			position: absolute;
			bottom: 100%;

			@keyframes logo {
				0% {
					opacity: 0;
					position: static;
					bottom: 0%;
				}
				40% {
					opacity: 0;
					position: static;
					bottom: 0%;
				}
				80% {
					opacity: 1;
					position: static;
					bottom: 0%;
				}
				90% {
					opacity: 0;
					position: static;
					bottom: 0%;
				}
				100% {
					position: static;
					bottom: 0%;
				}
			}
			
		}
	}

	&__popup {
		width: 100%;
		height: 0vh;
		position: absolute;
		left: 0%;
		top: 0%;
		background-color: $dark;
		z-index: 2;
		transition: 0.5s;
	}
	
	&__popup-open {
		height: 100vh;
		transition: 0.5s;
	}

	.home__header--phantom {
		height: 80px;
		width: 100%;
	}

	&__header {
		padding: 0 180px;
		position: absolute;
		left: 0;
		right: 0;
		width: 100%;
		height: 80px;
		z-index: 9;
		@include display-center(space-between, center);

		&._header-show {
            position: fixed;
            top: 0px;
            transition: .5s;
        }

		&._header-hide {
            position: fixed;
            top: -100px;
            transition: .5s;
		}

		&._hide-bg {
			background: transparent;
			backdrop-filter: blur(0);
		}

		.logo img {
			width: 300px; 
			animation: fadeFromLeft 2s 3.5s ease;
		}

		.box {
			@include display-center(flex-start, center);
			animation: fadeFromRight 2s 3.5s ease;

			
			&__navigation {
				margin-right: 80px;

				ul {
					display: flex;
					gap: 25px;
					li {
						@include font($white, 28px, 400, normal, 0);
						transition: 0.5s;
						cursor: pointer;
						&:hover {
							color: $main;
							transition: 0.5s;
						}
					}
				}
			}

			&__language {
				width: 160px;
				height: 68px;
				border-radius: 3px 3px 0px 0px;
				background: #ffffff6d;
				cursor: pointer;

				&-box {
					width: 100%;
					height: 68px;
					@include display-center(center, center);

					transition: 0.5s;
					background: #ffffff6d;

					gap: 20px; 
					
					&:hover {
						background-color: $blue;
						transition: 0.5s;
					}
				}

				&-boxHide {
					display: none;
				}

				&-boxShow {
					display: flex;
				}

				&-flat {
					width: 50px; 
					height: 32px; 
					background-size: cover;
				}

				&-cz {
					background-image: url(../img/cz.svg);
				}

				&-eu {
					background-image: url(../img/gb.svg);
				}

				&-arrow {
					width: 44px;
					height: 44px;
					background-size: cover;
				}

				&-arrowimg {
					background-image: url(../img/arrow.svg);
					transition: 0.5s;
				}

				&-arrowimgRow {
					background-image: url(../img/arrow.svg);
					transform: rotate(180deg);
					transition: 0.5s;
				}
			}

			&__burger {
				display: none;
			}
		}
	}

	.main {
		width: 100%;
		height: 88%;
		@include display-center(flex-start, center);
		animation: fadeFromLeft 2s 3.5s ease;


		&__element {
			width: 460px;
			height: 700px;
			border-left: 12px solid $main;
			border-top: 12px solid $main;
			border-bottom: 12px solid $main;
			
			border-top-left-radius: 5px;
			border-bottom-left-radius: 5px;
			border-top-right-radius: 5px;
			border-bottom-right-radius: 5px;
			
			@include display-center(flex-end, center);
			position: relative;
			animation: fadeFromLeft 2s 3.5s ease;



			&-title {
				width: 300%;
				@include font(#fff, 70px, 700, normal, 0);
				position: absolute;
				left: 0%;
				margin-bottom: 100px;
				margin-left: 200px;
			}

			&-color {
				color: $main;
			}

			&::after {
				content: "";
				width: 12px;
				height: 110px;
				position: absolute;
				left: 100%;
				top: 0px;
				margin-left: -12px;
				background-color: $main;
			}

			&::before {
				content: "";
				width: 12px;
				height: 200px;
				position: absolute;
				left: 100%;
				bottom: 0px;
				margin-left: -12px;
				background-color: $main;
			}
		}

		&__container {
			width: auto;
			height: 700px;
			padding-left: 30px;
			@include display-center(flex-end, flex-start);
			flex-direction: column;

			&-descripiton {
				max-width: 950px;
				@include font(#fff, 32px, 400, normal, 0); 
				margin-bottom: 25px;
			}

			&-sociable {
				display: flex;
				gap: 15px;
			}
		}
	}
}

@keyframes fadeFromLeft {
	0% { transform: translateX(-100px); opacity: 0;}
	100% { transform: translateX(0px); opacity: 1;}
}


@keyframes fadeFromRight {
	0% { transform: translateX(100px);  opacity: 0;}
	100% { transform: translateX(0px);  opacity: 1;}
}

@keyframes fadeFromBottom {
	0% { transform: translateY(50px);  opacity: 0;}
	100% { transform: translateY(0px);  opacity: 1;}
}







@media screen and (max-height: 800px) {	
	.home {
		padding: 0 160px;

		.page__popup {
			.logo {
				min-width: 320px;
				min-height: 80px;
			}
		}

		&__header {
			.logo img {
				width: 200px;
			}

			.box {
				&__navigation {
					ul {
						li {
							font-size: 24px;
							transition: 0.5s;
						}
					}
				}

				&__language {
					width: 120px;
					height: 50px;
	
					&-box {
						width: 100%;
						height: 50px;
						gap: 20px;
					}

					&-flat {
						width: 36px;
						height: 24px;
					}

					&-arrow {
						width: 34px;
						height: 34px;
						background-size: cover;
					}
				}
			}
		}


		.main {
			animation: none;

			&__element {
				width: 380px;
				height: 550px;
				border-left: 7px solid $main;
				border-top: 7px solid $main;
				border-bottom: 7px solid $main;
				
				&-title {
					width: 340%;
					font-size: 56px;
					margin-bottom: 100px;
					margin-left: 160px;
					animation: fadeFromLeft 2s 3.5s ease;
				}
	
				&-color {
					color: $main;
				}
	
				&::after {
					width: 7px;
					height: 110px;
					margin-left: -7px;
				}
	
				&::before {
					width: 7px;
					height: 200px;
					margin-left: -7px;
				}
			}
	

			&__container {
				height: 550px;
	
				&-descripiton {
					margin-bottom: 30px;
					width: 720px;
					font-size: 24px;
					animation: fadeFromRight 2s 3.5s ease;
				}

				&-sociable {
					animation: fadeFromBottom 2s 3.5s ease;
				}
			}
		}
	}
}

@media screen and (max-width: 2000px) {	
	.home {
		padding: 0 160px;

		.page__popup {
			.logo {
				min-width: 340px;
				min-height: 110px;
			}
		}

		&__header {
			.logo img {
				width: 200px;
			}

			.box {
				&__navigation {
					ul {
						li {
							font-size: 24px;
							transition: 0.5s;
						}
					}
				}

				&__language {
					width: 120px;
					height: 50px;
	
					&-box {
						width: 100%;
						height: 50px;
						gap: 20px;
					}

					&-flat {
						width: 36px;
						height: 24px;
					}

					&-arrow {
						width: 34px;
						height: 34px;
						background-size: cover;
					}
				}
			}
		}


		.main {
			&__element {
				width: 380px;
				height: 550px;
				border-left: 7px solid $main;
				border-top: 7px solid $main;
				border-bottom: 7px solid $main;
				
				&-title {
					font-size: 56px;
					margin-bottom: 100px;
					margin-left: 160px;
				}
	
				&-color {
					color: $main;
				}
	
				&::after {
					width: 7px;
					height: 110px;
					margin-left: -7px;
				}
	
				&::before {
					width: 7px;
					height: 200px;
					margin-left: -7px;
				}
			}
	

			&__container {
				height: 550px;
	
				&-descripiton {
					margin-bottom: 30px;
					width: 720px;
					font-size: 24px;
				}
			}
		}
	}
}


@media screen and (max-width: 2000px) and (max-height: 900px) {
	.home {
		.main {
			&__element {
				width: 400px;
				height: 540px;
				border-left: 7px solid $main;
				border-top: 7px solid $main;
				border-bottom: 7px solid $main;
				
				&-title {
					font-size: 56px;
					margin-bottom: 100px;
					margin-left: 180px;
				}
	
				&-color {
					color: $main;
				}
	
				&::after {
					width: 7px;
					height: 90px;
					margin-left: -7px;
				}
	
				&::before {
					width: 7px;
					height: 180px;
					margin-left: -7px;
				}
			}
	

			&__container {
				height: 540px;
	
				&-descripiton {
					margin-bottom: 30px;
					width: 760px;
					font-size: 28px;
				}
			}
		}
	}
}

@media screen and (max-width: 2000px) and (max-height: 850px) {
	.home {
		.main {
			&__element {
				width: 320px;
				height: 540px;

				&-title {
					font-size: 56px;
					margin-bottom: 100px;
					margin-left: 180px;
				}
			}

			&__container {
				height: 540px;
	
				&-descripiton {
					margin-bottom: 30px;
					width: 760px;
					font-size: 28px;
				}
			}
		}
	}
}



@media screen and (max-width: 1700px) {
	.home {
		padding: 0 120px;


		.page__popup {
			.logo {
				min-width: 260px;
				min-height: 85px;
			}
		}

		&__header {
			padding: 0 140px;
			.logo img {	
				width: 160px;
			}

			.box  {
				&__navigation {
					margin-right: 60px;
	
					ul {
						li {
							font-size: 20px;
						}
					}
				}

				&__language {
					width: 95px;
					height: 40px;
					border-radius: 3px 3px 0px 0px;
	
					&-box {
						height: 40px;
					}
	
					&-flat {
						width: 28px;
						height: 18px;
					}
	
					&-arrow {
						width: 22px;
						height: 22px;
					}
				}
			}
		}

		.main {
			width: 100%;
			height: 88%;
			@include display-center(flex-start, center);
	
	
			&__element {
				width: 350px;
				height: 480px;
				border-left: 7px solid $main;
				border-top: 7px solid $main;
				border-bottom: 7px solid $main;
	
				&-title {
					width: 265%;
					font-size: 48px;
					margin-bottom: 100px;
					margin-left: 120px;
				}
	
				&::after {
					height: 60px;
					margin-left: -7px;
				}
	
				&::before {
					height: 140px;
					margin-left: -7px;
				}
			}
	
			&__container {
				width: auto;
				height: 480px;
				padding-left: 20px;
	
				&-descripiton {	
					width: 500px;
					font-size: 20px;
					margin-bottom: 25px;
				}
			}
		}
	}
}


@media screen and (max-width: 1700px) and (max-height: 750px) {
	.home {
		.main {
			&__element {
				width: 270px;
				height: 410px;

				&-title {
					width: 350%;
					font-size: 44px;
					margin-bottom: 100px;
					margin-left: 140px;
				}

				&::after {
					width: 7px;
					height: 40px;
					margin-left: -7px;
				}
	
				&::before {
					width: 7px;
					height: 130px;
					margin-left: -7px;
				}
			}

			&__container {
				height: 410px;
	
				&-descripiton {	
					width: 500px;
					font-size: 20px;
					margin-bottom: 25px;
				}
			}
		}
	}
}


@media screen and (max-width: 1200px) {
	.home {
		padding: 0 80px;

		&__header {
			padding: 0 80px;
			.logo {
				z-index: 3;
			}
			.box {
				&__navigation {
					width: 100%;
					height: 100vh;
					position: absolute;
					left: 0%;
					top: 0%;
					margin-right: 50px;
					position: absolute;
					z-index: 3;
					@include display-center(center, center);
					display: none;



					
					ul {
						gap: 20px;
						flex-direction: column;
						text-align: center;
						li {
							font-size: 24px;
						}
					}
				}

				&__navigation-open {
					display: flex;
					ul {
						animation-name: navigation;
						animation-duration: 0.8s;
						animation-iteration-count: 1;
					}
					@keyframes navigation {
						0% {
							position: relative;
							bottom: 50px;
							opacity: 0;
						}

						80% {
							position: relative;
							bottom: 50px;
							opacity: 0;
						}
						100% {
							position: relative;
							bottom: 0px;
							opacity: 1;
						}
					}
				}


				&__language {
					margin-right: 45px;
				}

				&__burger {
					width: 35px;
					height: 35px;
					
					@include display-center(space-around, center);
					flex-direction: column;
					display: flex;
					overflow: hidden;
					z-index: 3;

					&-row {
						width: 100%;
						height: 1.5px;
						background-color: $white;
						transition: 0.5s;

					}

					&-row2 {
						transition: 0.5s;
						position: relative;
						right: 0px;

					}

					&-row1Show {
						transition: 0.5s;
						transform: rotate(-45deg) translate(-10px, 13px);
					}

					&-row2Show {
						transition: 0.5s;
						position: relative;
						right: 100px;

					}

					&-row3Show {
						transition: 0.5s;
						transform: rotate(45deg) translate(-3px, -8px);
					}
					
				}

			}
		}

		.main {
			&__element {
				width: 280px;
				height:450px;
				@include display-center(flex-end, center);
				position: relative;
				&::after {
					content: "";
					height: 70px;
	
				}
	
				&::before {
					content: "";
					height: 160px;
	
				}
				&-title {
					width: 220%;
					@include font(#fff, 32px, 700, normal, 0);
					position: absolute;
					left: 0%;
					margin-bottom: 100px;
					margin-left:45%;
				}
			}
			&__container {
				width: auto;
				height: 450px;
				padding-left: 20px;
				@include display-center(flex-end, flex-start);
				flex-direction: column;
	
				&-descripiton {
					width: 390px;
					@include font(#fff, 18px, 400, normal, 0);
					margin-bottom: 25px;
				}
	
				&-sociable {
					display: flex;
					gap: 15px;
				}
	
			}
		}
	}


}


@media screen and (max-width: 800px) {
	.home {
		padding: 0 30px;
		.page__popup {
			.logo {
				min-width: 200px;
				min-height: 65px;	
			}
	
		}
	
		&__header {
			padding: 0 60px;
			.logo {
				img {
					width: 130px;
				}
			}
			.box {
				&__language {
					margin-right: 25px;
				}
			}
		}

		.main {
			@include display-center(center, center);
			flex-direction: column;
			&__element {
				width: 100%;
				height:auto;
				@include display-center(center, center);
				position: relative;
				border: none;
				margin-top: 25%;
				&::after {
					display: none;
				}
	
				&::before {
					display: none;
				}
				&-title {
					width: 100%;
					@include font(#fff, 32px, 700, normal, 0);
					margin-bottom: 0px;
					margin-left: 0%;
					text-align: center;
				}
			}
			&__container {
				width: auto;
				height: 320px;
				padding-left: 0px;
				@include display-center(center, center);
				flex-direction: column;
	
				&-descripiton {
					width: 80%;
					@include font(#fff, 18px, 400, normal, 0);
					margin-bottom: 35px;
					text-align: center;
				}
	
				&-sociable {
					@include display-center(center, center);
					gap: 15px;
				}
	
			}
		}
	}
}


@media screen and (max-width: 500px) {
	.home {
		&__header {
			padding: 0 30px;
			.logo {
				img {
					width: 100px;
				}
			}
			.box {
				&__language {
					width: 60px;
					height: 35px;
					margin-right: 20px;
					&-box {
						height: 35px;
						gap: 10px;

					}
					&-arrow {
						display: none;
					}
				}
			}
		}

		.main {
			&__element {
				&-title {
					width: 100%;
					@include font(#fff, 22px, 700, normal, normal);
					margin-bottom: 0px;
					margin-left: 0%;
					text-align: center;
				}
			}
			&__container {
				&-descripiton {
					width: 100%;
					@include font(#fff, 18px, 400, normal, 0);
					margin-bottom: 35px;
					text-align: center;
				}
	
				&-sociable {
					@include display-center(center, center);
					gap: 15px;
				}
	
			}
		}
	}
}
 

@media screen and (max-height: 600px) {
	.home {
		.page__popup {
			.logo {
				min-width: 200px;
				min-height: 65px;	
			}
	
		}
	
		&__header {
			.logo {
				img {
					width: 130px;
				}
			}
			.box {
				&__language {
					margin-right: 25px;
				}
			}
		}

		.main {
			animation: none;
			@include display-center(center, center);
			flex-direction: column;
			&__element {
				width: 100%;
				height:auto;
				@include display-center(center, center);
				position: relative;
				border: none;
				margin-top: 15%;
				margin-bottom: -30px;
				&::after {
					display: none;
				}
	
				&::before {
					display: none;
				}
				&-title {
					width: 100%;
					@include font(#fff, 24px, 700, normal, 0);
					margin-bottom: 20px;
					margin-left: 0%;
					text-align: center;
					animation: fadeFromLeft 2s 3.5s ease;
				}
			}
			&__container {
				width: auto;
				height: 320px;
				padding-left: 0px;
				@include display-center(center, center);
				flex-direction: column;
	
				&-descripiton {
					width: 100%;
					@include font(#fff, 18px, 400, normal, 0);
					margin-bottom: 35px;
					text-align: center;
					animation: fadeFromRight 2s 3.5s ease;
				}
	
				&-sociable {
					@include display-center(center, center);
					gap: 15px;
					animation: fadeFromBottom 2s 3.5s ease;
				}
	
			}
		}
	}
}


@media screen and (max-height: 550px) {
	.home {
		&__header {
			padding-top: 20px;
			.box {
				&__navigation {
					ul {
						gap: 10px;
						li {
							position: relative;
							bottom: 20%;
							font-size: 18px;

						}
					}
				}
			}
		}

		.main {
			flex-direction: row;

			&__element {
				width: 180%;
				height:auto;
				@include display-center(center, center);
				position: relative;
				border: none;
				margin-top: 0;

				&-title {
					font-size: 28px;
					text-align: left;
				}
			}

			&__container {
				width: auto;
				height: 320px;
	
				&-descripiton {
					width: 80%;
					margin-bottom: 20px;
				}
			}
		}
	}
}


@media screen and (max-height: 550px) and (max-width: 770px){
	.home {
		&__header {
			padding-top: 20px;
		}

		.main {
			&__element {
				width: 220%;
				height:auto;
				@include display-center(center, center);
				position: relative;
				border: none;
				margin-top: 0;

				&-title {
					font-size: 26px;
					text-align: left;
				}
			}

			&__container {
				width: auto;
				height: 320px;
	
				&-descripiton {
					width: 80%;
					font-size: 16px;
					margin-bottom: 15px;
				}
			}
		}
	}
}