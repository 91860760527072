*{
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

:focus,:active{outline: none;}
a:focus,a:active{outline: none;}

input,button,textarea{font-family:inherit;}

button{cursor: pointer;}
button::-moz-focus-inner {padding:0;border:0;}
a, a:visited{text-decoration: none;}
a:hover{text-decoration: none;}
ul li{list-style: none;}